var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TPop',{attrs:{"visible":_vm.isShow},on:{"update:visible":function($event){_vm.isShow=$event}}},[_c('div',{staticClass:"box",on:{"click":_vm.showSuecces}},[_vm._l((_vm.imgList),function(item,index){return _c('div',{key:index,staticClass:"img-box",style:(`z-index:${Math.floor(item.scale * 100)};
      left:${item.left}%;
      top:calc(${item.top}% - var(--width));
      animation-delay:${item.delay}s;
      rotate:${item.rotate}deg;
      --scale:${item.scale};
      --duration:${5 / item.scale}s`)},[_c('img',{attrs:{"src":require("@/assets/images/redEnvelopeRainCard.png"),"alt":""}})])}),_c('img',{staticClass:"foot-img",attrs:{"src":require("@/assets/images/redEnvelopeRainFoot.png")}}),_c('img',{staticClass:"left-img",attrs:{"src":require("@/assets/images/redEnvelopeRainLeft.webp")}}),_c('img',{staticClass:"right-img",attrs:{"src":require("@/assets/images/redEnvelopeRainRight.webp")}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }