import request from '@/utils/request'

// 获取红包雨列表
export function red_packet_list_api(params) {
  return request({
      url: '/red_packet/list',
      method: 'get',
      params
  })
}


// 领取红包
export function red_packet_receive_api(params) {
  return request({
      url: '/red_packet/receive',
      method: 'get',
      params
  })
}