<template>
  <TPop :visible.sync="isShow">
    <div class="red-envelope-pop-box">
      <div class="red-envelope-pop-header">{{ $t("红包雨奖励") }}</div>
      <div class="red-envelope-pop-content">
        <div class="price" :data-text="`${rainData.currency} ${numFormat(rainData.now_data.award_limit)}`">{{ rainData.currency }} {{numFormat(rainData.now_data.award_limit)}}</div>
        <div class="price-tip">{{ $t("每次") }}</div>
        <div class="card-item">
          <div class="item-title">{{ rainData.now_day_str }}</div>
          <div class="item-content">
            <div class="time-item" v-for="item,index in rainData.now_day" :key="index">{{ item.time }}</div>
          </div>
        </div>
        <div class="card-item">
          <div class="item-title">{{ rainData.next_day_str }}</div>
          <div class="item-content">
            <div class="time-item" v-for="item,index in rainData.next_day" :key="index">{{ item.time }}</div>
          </div>
        </div>
        <ul class="tips">
          <li class="tip-item">{{ $t("永久奖励") }}</li>
          <li class="tip-item">{{ $t("每轮奖金：") }}{{ rainData.currency }} {{numFormat(rainData.now_data.award_limit)}}</li>
          <li class="tip-item">{{ $t("最高奖金：") }}{{ rainData.currency }} {{numFormat(rainData.now_data.max_award)}}/{{$t("人")}}</li>
        </ul>
        <el-button class="btn button_fullwidth" v-if="rainData.now_data.join==0" @click="showWallet">{{ $t("参与活动") }}</el-button>
        <div class="foot" v-else>
          <div class="foot-title">{{ $t("开始于") }}</div>
          <div class="foot-time">{{ rainData.now_data.time.split("-")[0] }}:00</div>
        </div>
      </div>
    </div>
  </TPop>
</template>

<script>
import TPop from "../TPop.vue";
import { EventBus } from "@/common/eventBus.js";
export default {
  components: { TPop },
  data() {
    return {
      isShow: false,
    };
  },
  props:{
    rainData:{
      default:null
    }
  },
  methods: {
    show(){
      this.isShow = true
    },
    showWallet() {
      this.isShow = false
      EventBus.$emit("showWallet")
		},
  },
};
</script>

<style scoped lang="less" src="@/assets/css/redEnvelopeRainPop.less"></style>
