import { render, staticRenderFns } from "./msgPop.vue?vue&type=template&id=90c2281a&scoped=true&"
import script from "./msgPop.vue?vue&type=script&lang=js&"
export * from "./msgPop.vue?vue&type=script&lang=js&"
import style0 from "./msgPop.vue?vue&type=style&index=0&id=90c2281a&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90c2281a",
  null
  
)

export default component.exports