// 判断用户历史网站一小时，再次进入强制刷新
import PageVisibilitychange from "@/utils/PageVisibilitychange"
const pageVisibilitychange = new PageVisibilitychange();
let hideTime;
pageVisibilitychange.show(()=>{
  if(new Date().getTime() - hideTime > 3600 * 1000){
    location.reload()
  }
})
pageVisibilitychange.hide(()=>{
  hideTime = new Date().getTime()
})