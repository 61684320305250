class PageVisibilitychange {
  showCallBack = null
  hideCallBack = null
  constructor(){
    document.addEventListener("visibilitychange", this.event.bind(this));
  }
  event(){
    if (document.visibilityState === 'hidden') {
      this.hideCallBack && this.hideCallBack()
    } else {
      this.showCallBack && this.showCallBack()
    }
  }
  show(callBack){
    this.showCallBack = callBack
    this.event()
  }
  hide(callBack){
    this.hideCallBack = callBack
  }
  clear(){
    document.removeEventListener("visibilitychange", this.event);
  }
}

export default PageVisibilitychange;