<template>
  <div ref="moveEl" @touchmove.prevent="touchmove" @touchstart="touchstart"  @touchend="touchend" 
  :style=" `right: ${right}px; bottom:${bottom}px;`" :class="{transition:isTransition}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data(){
    return {
      touchStartData:null,
      right:"",
      bottom:"",
      isTransition:false,
      timeId: null
    }
  },
  props:{
    magnetic:{
      default:false
    },
    limitPosition:{
      default:()=>({
        left:0,
        right:0,
        bottom:0,
        top:0
      })
    }
  },
  methods:{
    touchmove(e){
      if(!this.touchStartData) return
      let x,y;
      if(e.changedTouches && e.changedTouches[0]){
        const {clientX,clientY} = e.changedTouches[0]
        x = clientX
        y = clientY
      }else{
        const {clientX,clientY} = e
        x = clientX
        y = clientY
      }
      
      this.right = this.touchStartData.oldRight + (this.touchStartData.clientX  - x)
      this.bottom = this.touchStartData.oldBottom + (this.touchStartData.clientY  - y)
    },
    limit(){
      // 左右磁吸
      if(this.magnetic){
        if(this.$refs.moveEl.offsetLeft + this.$refs.moveEl.offsetWidth / 2 > window.innerWidth/2){
          this.right = this.limitPosition.right
        }else{
          this.right = window.innerWidth - this.$refs.moveEl.offsetWidth - this.limitPosition.left
        }
      }else{
        if(this.getRight() < this.limitPosition.right){
          this.right = this.limitPosition.right
        }
        if(this.$refs.moveEl.offsetLeft < this.limitPosition.left){
          this.right = window.innerWidth - this.$refs.moveEl.offsetWidth - this.limitPosition.left
        }
      }

      if(this.getBottom() < this.limitPosition.bottom){
        const viewport = window.visualViewport;
        const safeAreaBottom = viewport.safeArea?.bottom || 0;
        this.bottom = this.limitPosition.bottom  + safeAreaBottom
      }
      if(this.$refs.moveEl.offsetTop < this.limitPosition.top){
        this.bottom = window.innerHeight - this.$refs.moveEl.offsetHeight  - this.limitPosition.top
      }
      
    },
    touchstart(e){
      this.timeId && clearTimeout(this.timeId)
      this.isTransition = false
      
      if(e.changedTouches && e.changedTouches[0]){
        this.touchStartData = e.changedTouches[0]
      }else{
        this.touchStartData = e
      }
      this.right = this.getRight()
      this.bottom =this.getBottom()
      this.touchStartData.oldRight = this.right
      this.touchStartData.oldBottom = this.bottom
    },
    touchend(){
      this.isTransition = true
      this.touchStartData = null
      this.$nextTick(()=>{
        this.limit()
        this.timeId = setTimeout(()=>{
          this.isTransition = false
        },310)
      })
    },

    getRight(){
      return window.innerWidth - this.$refs.moveEl.offsetLeft - this.$refs.moveEl.offsetWidth
    },
    getBottom(){
      return window.innerHeight - this.$refs.moveEl.offsetTop - this.$refs.moveEl.offsetHeight
    }
  },
  mounted() {
    this.right = this.getRight()
    this.bottom =this.getBottom()
    this.$refs.moveEl.style.top = "auto"
    this.$refs.moveEl.style.left = "auto"
  },
}
</script>

<style scoped lang="less">
.transition{
  transition: all .3s;
}
</style>